import { IInventoryReport } from './../../../api/source/models/database/types';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, SelectAddAll } from '../services/account.service';
import { InventoryCountService } from '../services/inventoryCount.service';
import { baseRole } from '../shared/baseRole';
import { ExpenseType, moment } from '../shared/api';
import {
  ExpenseAccountService,
  IExpenseAccount,
} from '../services/expenseAccount.service';
import { FoodVendorService } from 'source/services/foodVendor.service';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';

@Component({
  templateUrl: './inventoryCount.html',
})
export class InventoryCountComponent extends baseRole implements OnInit {
  public loc: any;
  public now: Date;
  public totalNum = 0;
  public totalPrice = 0;
  public expenseAccounts: IExpenseAccount[];
  // public filterInventoryData: IInventoryReport[];
  // public filterValue: number;
  public filterKey = 'inventory_sku_filter_key';
  public filterData: {
    expense_account_id: string;
  };
  public _inventoryData: IInventoryReport[];
  public inventoryData: IInventoryReport[];

  public user;
  public dashboards = [
    {
      name: 'Beginning And Ending Inventory Report',
      id: 'bb37757f-c5b3-49b3-b508-f453a6efb4b8'
    },
    {
      name: 'Price Comparison Across Multiple Vendors Report',
      id: '5dab37e0-1cb9-4c3f-88ab-dee5b7d1cf6f'
    },
    {
      name: 'Actual vs. Theoretical (AVT) Report',
      id: 'b4b7df5d-7cf2-479a-9800-a9479a868dfc'
    },
    {
      name: 'Analysis of Ingredients per Dish Report',
      id: 'e1ff5a7b-8441-402a-b840-b65c3bac3433'
    },
    {
      name: 'Comparing Different Locations Report',
      id: '057e9817-6c8b-4b6e-bcb7-1382bb4eceb7'
    }
  ];

  private embeddingContext: any;
  private embeddedDashboard: any;
  public selectedReport = 'inventoryQuantity';

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected inventoryCountService: InventoryCountService,
    protected accountService: AccountService,
    protected expenseAccountService: ExpenseAccountService,
    private elementRef: ElementRef,
    protected foodVendorService: FoodVendorService
  ) {
    super(accountService);
  }
  getDefaultFilter() {
    return {
      expense_account_id: '',
    };
  }
  ngOnInit() {
    this.loc = this.accountService.getLocation();
    this.now = new Date();
    this.filterData = this.accountService.getTempState(
      this.filterKey,
      this.getDefaultFilter()
    );

    this.inventoryCountService.reportQuantity().subscribe(data => {
      for (let d of data) {
        this.totalNum += d.quantity;
        this.totalPrice += d.total;
      }
      this.inventoryData = data;
      this._inventoryData = data;
    });
    this.expenseAccountService
      .list({ expense_type: ExpenseType.COGS })
      .subscribe(data => {
        this.expenseAccounts = SelectAddAll(data, 'All COG Categories');
      });

    this.loadUser();
  }

  private loadUser(): void {
    this.user = this.accountService.getUser();
  }

  filename() {
    return 'inventory_quantity_' + moment().format('Y_M_D') + '.csv';
  }

  filter() {
    this.totalNum = 0;
    this.totalPrice = 0;
    this.accountService.setTempState(this.filterKey, this.filterData);

    if (this.filterData.expense_account_id) {
      this.inventoryData = this._inventoryData.filter(
        e => e.expense_account_id === Number(this.filterData.expense_account_id)
      );
    } else {
      this.inventoryData = this._inventoryData;
    }
    for (let d of this.inventoryData) {
      this.totalNum += d.quantity;
      this.totalPrice += d.total;
    }
  }

  get selectedDashboardName(): string {
    if (this.selectedReport === 'inventoryQuantity') {
      return 'Inventory Quantity Report';
    }
    const dashboard = this.dashboards.find(d => d.id === this.selectedReport);
    return dashboard ? dashboard.name : '';
  }

  onReportChange() {
    if (this.selectedReport !== 'inventoryQuantity') {
      setTimeout(() => {
        this.onDashboardSelect(this.selectedReport);
      }, 0);
    }
  }

  public onDashboardSelect(dashboardId: string): void {
    const contentOptions = {
      dashboardId: dashboardId,
      parameters: [
        {
          Name: 'locationId',
          Values: [this.loc.id]
        }, 
        {
          Name: 'userId',
          Values: [this.user.id]
        }
      ],
      sheetOptions: {
        emitSizeChangedEventOnSheetChange: true
      }
    };

    if (this.embeddedDashboard) {
      this.embeddedDashboard.navigateToDashboard(dashboardId, contentOptions);
    } else {
      this.loadDashboard(dashboardId);
    }
  }

  async loadDashboard(dashboardId: string): Promise<void> {
    const dashboard = this.dashboards.find(d => d.id === dashboardId);

    if (dashboard && !this.embeddedDashboard) {
      const containerDiv = this.elementRef.nativeElement.querySelector(`#qsDashboardContainer`);

      this.foodVendorService.getDashboards(this.user.email, dashboardId).subscribe(
        async (data) => {
          const frameOptions = {
            url: data.EmbedUrl,
            container: containerDiv,
            scrolling: "no",
            height: "100%",
            width: "100%",
            locale: "en-US",
            footerPaddingEnabled: true,
            resizeHeightOnSizeChangedEvent: true,
            className: "card mb-0 mt-0",
            withIframePlaceholder: true,
          };

          const contentOptions = {
            dashboardId: dashboardId,
            parameters: [
              {
                Name: 'locationId',
                Values: [this.loc.id]
              }, 
              {
                Name: 'userId',
                Values: [this.user.id]
              }
            ],
            locale: "en-US",
            toolbarOptions: {
              export: true,
              undoRedo: false,
              reset: false
            },
            attributionOptions: {
              overlayContent: false,
            },
            sheetOptions: {
              emitSizeChangedEventOnSheetChange: true
            },
          };

          this.embeddingContext = await QuickSightEmbedding.createEmbeddingContext({
            onChange: (changeEvent, metadata) => {
              console.log('Context received a change', changeEvent, metadata);
            },
          });

          this.embeddedDashboard = await this.embeddingContext.embedDashboard(frameOptions, contentOptions);
        },
        (error) => {
          console.error('Error loading dashboard:', error);
        }
      );
    }
  }

}
