import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import {
  RecipeBlockService,
  IRecipeBlock,
} from '../services/recipeBlock.service';
import { ItemRecipeService } from '../services/itemRecipe.service';
import { SkuService, ISku } from '../services/sku.service';
import { baseRole } from '../shared/baseRole';
import {
  SkuUnit,
  SkuUnitNames,
  gramsToSkuUnit,
  skuUnitToGrams,
  SkuCostMethod,
  SelectAddAll,
  StringSort,
} from '../shared/api';

//This is for Edit Variation Recipe for Menu.

@Component({
  selector: 'modal-recipe',
  templateUrl: 'modalRecipe.html',
})
export class ModalRecipeComponent extends baseRole implements OnInit {
  // My Data and related.
  public recipe: any;
  public data: any;
  public items: any[];
  public itemSkuList: any[];
  public itemBlockList: any[];
  // New bits
  public newBlock: any = {};
  public newSku: any = {};
  // Available items.
  public blockList: IRecipeBlock[];
  public addNewBlockList: ISku[];
  public skus: ISku[];
  public addNewSkus: ISku[];
  // Misc.
  public SkuCostMethod = SkuCostMethod;
  public SkuUnitNames = SkuUnitNames;
  public SkuUnitKeys = Object.keys(SkuUnit).filter(Number);

  @ViewChild('modal')
  public modal: ModalDirective;

  @Output() onDone: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public accountService: AccountService,
    public itemRecipeService: ItemRecipeService,
    public skuService: SkuService,
    public recipeBlockService: RecipeBlockService
  ) {
    super(accountService);
  }

  ngOnInit() { }

  initNewBlock() {
    this.newBlock = {
      item_id: this.recipe.id,
      item_variation_id: this.data.id,
      qty: 1,
      unit: SkuUnit.Grams,
      name: '',
      recipe_block_id: '',
    };
  }
  initNewSku() {
    this.newSku = {
      name: '',
      cost_method: 2,
      item_id: this.recipe.id,
      item_variation_id: this.data.id,
      qty: 1.0,
      qty_visual: 1.0,
      unit: SkuUnit.Grams,
      sku_id: '',
      sku: {},
    };
  }

  save(i) {
    if (i.sku_id) {
      if (i.cost_method == 1) i.qty = i.qty_visual;
      else i.qty = skuUnitToGrams(i.unit, i.qty_visual);
    }
    this.itemRecipeService.update(i).subscribe(data => { });
    this.recalc();
  }

  load() {
    this.initNewBlock();
    this.initNewSku();
    forkJoin([
      this.recipeBlockService.list(),
      this.itemRecipeService.list({ item_variation_id: this.data.id }),
      this.skuService.list(),
    ]).subscribe(results => {
      this.blockList = results[0];
      this.addNewBlockList = SelectAddAll(
        results[0].slice(0),
        'Please select a Recipe to Add'
      );

      let data = (<ISku[]>results[2]).filter(e => {
        return e.cost_method != SkuCostMethod.Neither;
      });
      StringSort(data, 'name');
      this.skus = data;
      this.addNewSkus = SelectAddAll(
        data.slice(0),
        'Please select an ingredient to Add'
      );

      this.items = results[1]; // these are the items.
      for (let it of this.items) {
        if (it.sku_id) {
          //just  skus
          let i = this.skus.filter(e => {
            return e.id == it.sku_id;
          })[0];
          if (it.cost_method == 1) it.qty_visual = it.qty;
          else it.qty_visual = gramsToSkuUnit(it.unit, it.qty);
        }
      }

      this.recalc();
      this.modal.show();
    });
  }

  show(recipe: any, data: any) {
    //console.log(recipe | JSON.stringify(obj))
    this.recipe = recipe;
    this.data = data;
    this.load();
  }

  cancel() {
    this.onDone.emit(this.recipe);
    this.modal.hide();
  }

  findCostMethod(obj) {
    //		console.log(this.skus);
    let sku = this.skus.filter(e => {
      return e.id == obj.sku_id;
    })[0];
    obj.cost_method = sku.cost_method;
    this.save(obj);
  }

  changeNewSku() {
    this.newSku.sku = this.skus.filter(e => {
      return e.id == this.newSku.sku_id;
    })[0];
  }

  addNewBlock() {
    /*	console.log(this.newBlock.recipe_block_id);
      let blocks = this.getBlocks();
      let block = blocks.filter((e)=> {return e.recipe_block_id == this.newBlock.recipe_block_id})[0];
      this.newBlock.name= block.name;
   */
    this.itemRecipeService.update(this.newBlock).subscribe(data => {
      //		(<any>data).name = this.newBlock.name;
      this.items.push(data);
      this.initNewBlock();
      this.recalc();
    });
  }

  addSkuBlock() {
    this.newSku.name = this.newSku.sku.name;
    this.newSku.cost_method = this.newSku.sku.cost_method;
    this.newSku.qty = this.newSku.qty_visual;
    if (this.newSku.cost_method != 1)
      this.newSku.qty = skuUnitToGrams(
        this.newSku.unit,
        this.newSku.qty_visual
      );
    this.itemRecipeService.update(this.newSku).subscribe(data => {
      (<any>data).name = this.newSku.sku.name;
      (<any>data).cost_method = this.newSku.sku.cost_method;
      (<any>data).qty = this.newSku.qty;
      //		console.log (data);
      this.items.push(data);
      this.initNewSku();
      this.recalc();
    });
  }

  getSkus() {
    return this.items.filter(e => {
      return e.sku_id > 0;
    });
  }
  getBlocks() {
    return this.items.filter(e => {
      return e.recipe_block_id > 0;
    });
  }
  deleteItem(item) {
    this.itemRecipeService.delete(item.id).subscribe(() => {
      let idx = this.items.indexOf(item);
      this.items.splice(idx, 1);
      this.recalc();
    });
  }

  recalc() {
    this.data.cost = 0;
    this.data.kcal = 0;
    this.data.fat = 0;
    this.data.protein = 0;
    this.data.carbs = 0;
    this.itemSkuList = [];
    this.itemBlockList = [];
    for (let it of this.items) {
      if (it.sku_id) {
        //just skus
        let i = this.skus.filter(e => {
          return e.id == it.sku_id;
        })[0];
        /*	console.log("recalc with this sku");
          console.log(i);  */
        if (it.cost_method == 1) {
          it.qty_visual = it.qty;
          this.data.cost += (i.price / i.total_individual_units) * it.qty;
        } else {
          it.qty_visual = gramsToSkuUnit(it.unit, it.qty);
          this.data.cost += (i.price / i.master_weight) * it.qty;
        }

        /*		console.log("sku recalc")
          console.log(it); */
        this.itemSkuList.push(it);

        this.data.kcal += (i.kcal / i.nutritional_portion_weight) * it.qty;
        this.data.fat += (i.fat / i.nutritional_portion_weight) * it.qty;
        this.data.protein +=
          (i.protein / i.nutritional_portion_weight) * it.qty;
        this.data.carbs += (i.carbs / i.nutritional_portion_weight) * it.qty;
      } else {
        //blockList
        this.itemBlockList.push(it);
        let myblock = this.blockList.filter(e => {
          return e.id == it.recipe_block_id;
        });
        let i = myblock[0];
        this.data.cost += (i.price / i.qty) * it.qty;
        this.data.kcal += (i.kcal / i.qty) * it.qty;
        this.data.fat += (i.fat / i.qty) * it.qty;
        this.data.protein += (i.protein / i.qty) * it.qty;
        this.data.carbs += (i.carbs / i.qty) * it.qty;
      }
    }
  }
}
