<div class="container-fluid subnav-padding">
  <div class="row" *ngIf="!success">
    <div class="col-12">
      <strong>Instructions</strong>
      <p>
        Select the vendor whose prices you are updating, then select a
        properly-formatted CSV file to upload. We will display a preview of data
        to be imported after you upload your file.
      </p>
      <p><strong>CSV file format:</strong></p>
      <ul style="list-style: disc; padding-left: 30px">
        <li>
          The first column in your spreadsheet must contain the ingredient SKUs to be
          updated.
        </li>
        <li>The second column must contain the prices for each ingredient SKU.</li>
        <li>All additional columns will be ignored.</li>
        <li>Do not include a header row (with column titles).</li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="!success">
    <div class="col-12 col-md-4 mb-3">
      <label>Vendor</label>
      <select name="contact_id" class="form-control" [(ngModel)]="contact_id">
        <option *ngFor="let vendor of vendors" [value]="vendor.id">
          {{ vendor.company }}
        </option>
      </select>
    </div>
  </div>
  <div class="row" *ngIf="!success && contact_id">
    <div class="col-12 col-md-4 mb-3">
      <label>Ingredient Price File</label>
      <input
        type="file"
        (change)="onFileUpload($event)"
        multiple="false"
        size="1" />
    </div>
  </div>

  <alert type="warning" *ngIf="badPriceSkus?.length > 0">
    <strong>Possible badly formated ingredient SKUs: </strong><br />
    <div *ngFor="let sku of badPriceSkus">{{sku}}</div>
  </alert>

  <div class="row mt-2" *ngIf="!success && data && data.length > 0">
    <div class="col-12 col-md-4">
      <button type="button" class="btn btn-primary" (click)="submitFile()">
        Update
      </button>
      <strong class="float-right">File: {{ filename }}</strong>
    </div>
  </div>

  <table *ngIf="data && !success" class="table mt-3">
    <thead>
      <tr>
        <th>SKU</th>
        <th>Price</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let d of data">
        <td>{{ d.sku }}</td>
        <td>{{ d.price | currency }}</td>
      </tr>
    </tbody>
  </table>

  <a *ngIf="success" class="btn btn-primary my-2" routerLink="/inventory"
    >Back</a
  >

  <alert type="success" *ngIf="success">
    Success. {{importedSkus.length }} SKUs updated.
  </alert>

  <alert type="warning" *ngIf="missedSkus?.length > 0">
    <strong>SKUs that didn't match: </strong><br />
    <div *ngFor="let sku of missedSkus">{{sku}}</div>
  </alert>
</div>
