import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IApiUser } from '../services/account.service';
import {
  RecipeBlockService,
  IRecipeBlock,
} from '../services/recipeBlock.service';
import {
  RecipeBlockItemService,
  IRecipeBlockItem,
} from '../services/recipeBlockItem.service';
import {
  SkuService,
  ISku,
  SkuUnit,
  SkuUnitNames,
} from '../services/sku.service';
import { baseRole } from '../shared/baseRole';
import {
  SelectAddAll,
  gramsToSkuUnit,
  skuUnitToGrams,
  StringSort,
  SkuCostMethod,
} from '../shared/api';

@Component({
  templateUrl: 'recipeBlockEdit.html',
})
export class RecipeBlockEditComponent extends baseRole implements OnInit {
  public data: IRecipeBlock;
  public options: any[]; // Mixed skus & blocks public _items: any[];
  public _items: any[];
  public itemSkus: any[];
  public itemBlocks: any[];
  public addSkuList: ISku[];
  public skuList: ISku[];
  public newSku: any = {};
  public newBlock: any = {};
  public _blockList: any[];
  public blockList: IRecipeBlock[];
  public addBlockList: IRecipeBlock[];
  public blockMap: any = {};
  public SkuUnitNames = SkuUnitNames;
  public SkuUnitKeys = Object.keys(SkuUnit).filter(Number);

  @Output() onDone: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public accountService: AccountService,
    public skuService: SkuService,
    public recipeBlockService: RecipeBlockService,
    public recipeBlockItemService: RecipeBlockItemService
  ) {
    super(accountService);
    this.initNewSku();
    this.initNewBlock();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.skuService.list().subscribe(data => {
        data = data.filter(e => {
          return e.cost_method != SkuCostMethod.Neither;
        });
        StringSort(data, 'name');
        this.skuList = data;
        this.addSkuList = SelectAddAll(
          data.slice(0),
          'Please select a SKU to Add'
        );
        /*	console.log('Sku List');
        console.log(this.skuList); */
        this.recipeBlockService.list().subscribe(data => {
          /*	console.log('data');
          console.log(data); */
          StringSort(data, 'name');
          if (id) {
            this.data = data.filter(e => {
              return e.id == id;
            })[0];
            this._blockList = data.filter(e => {
              return e.id != id;
            });
            this.recipeBlockItemService.list().subscribe(dataItems => {
              this._items = dataItems;
              this.itemSkus = dataItems.filter(e => {
                return e.recipe_block_id == id && e.sku_id;
              });
              for (let i of this.itemSkus) {
                i.qty_visual = gramsToSkuUnit(i.unit, i.qty);
                let selectedSKU = this.addSkuList.filter(e => {
                  return e.id == i.sku_id;
                });
                i.method = selectedSKU[0].cost_method;
              }

              this.itemBlocks = dataItems.filter(e => {
                return e.recipe_block_id == id && e.child_recipe_block_id;
              });
              for (let i of this.itemBlocks) {
                i.qty_visual = gramsToSkuUnit(i.unit, i.qty);
              }
              this.blockList = this.removeCircular();
              this.addBlockList = SelectAddAll(
                this.blockList.slice(0),
                'Please select a Recipe to Add'
              );
              this.updateBlock();
            });
          } else {
            // New? Don't worry about parenting...
            (<any>this.data) = {};
            this.data.qty = 1;
            this.itemSkus = [];
            this.itemBlocks = [];
            this._blockList = data;
            this.blockList = data;
            this.addBlockList = SelectAddAll(
              data,
              'Please select a Recipe to Add'
            );
          }

          /*console.log('Block List');
          console.log(this.blockList);*/
        });
      });
    });
  }

  private initNewSku() {
    this.newSku = {
      sku_id: '',
      unit: SkuUnit.Grams,
      qty_visual: 1,
      method: 2, //cost method
    };
  }

  private initNewBlock() {
    this.newBlock = {
      child_recipe_block_id: '',
      unit: SkuUnit.Grams,
      qty: 1,
    };
  }

  newUnits() {
    let selectedSKU = this.addSkuList.filter(e => {
      return e.id == this.newSku.sku_id;
    });
    this.newSku.method = selectedSKU[0].cost_method;
    //		console.log(this.newSku);
  }

  setSkuItem(obj) {
    let selectedSKU = this.addSkuList.filter(e => {
      return e.id == obj.sku_id;
    });
    obj.method = selectedSKU[0].cost_method;
  }

  private removeCircular() {
    if (!this.data.id) {
      return this._blockList;
    }

    // This copies the data..
    let items = this._items.filter(e => {
      return e.child_recipe_block_id;
    });
    //console.log('Chekcing items');
    //console.log(items);

    let matched = true;
    let matchedIds = {};

    matchedIds[this.data.id] = true;

    while (matched) {
      matched = false;
      for (let i of items) {
        if (i.child_recipe_block_id in matchedIds) {
          matchedIds[i.recipe_block_id] = true;
          if (!i._matched) {
            matched = true;
            i._matched = true;
          }
        }
      }
      items = items.filter(e => {
        return !e._matched;
      });
    }
    /*console.log('Matched');
    console.log(matchedIds);*/
    return this._blockList.filter(e => {
      return !(e.id in matchedIds);
    });
  }

  save() {
    if (this.newBlock.child_recipe_block_id.length > 0) this.addBlock();
    if (this.newSku.sku_id) this.addSku();
    //		this.data.kcal = this.data.protein = this.data.fat = this.data.carbs = 0;

    for (let item of this.itemSkus) {
      item.qty = item.qty_visual;
      if (item.method != 1)
        item.qty = skuUnitToGrams(item.unit, item.qty_visual);
    }
    //does this need to be correct on the item before update?

    this.recipeBlockService.update(this.data).subscribe(result => {
      for (let item of this.itemSkus) {
        item.recipe_block_id = result.id;
        /*			item.qty=item.qty_visual;
        if(item.method !=1)
          item.qty=skuUnitToGrams(item.unit,item.qty_visual);
          done above*/
      }
      for (let item of this.itemBlocks) {
        item.recipe_block_id = result.id;
      }
      let p = [];
      if (this.itemBlocks.length > 0) {
        p.push(this.recipeBlockItemService.bulkUpdate(this.itemBlocks));
      }
      if (this.itemSkus.length > 0) {
        p.push(this.recipeBlockItemService.bulkUpdate(this.itemSkus));
      }
      //console.log('Going to join');
      forkJoin(p).subscribe(() => {
        //console.log('Done');
        this.router.navigate(['/inventory/recipe']);
      });
      if (p.length == 0) {
        this.router.navigate(['/inventory/recipe']);
      }
    });
  }

  checkItems() {
    /*		let lastItem = this.items[this.items.length-1];
    this.items = this.items.filter((e) => {
      return (e==lastItem || e.name || e.qty || e.unit);
    });
    if(lastItem.name && lastItem.qty && lastItem.unit) {
      this.items.push({});
    }*/
  }

  updateBlock() {
    this.data.price = 0;
    this.data.kcal = 0;
    this.data.protein = 0;
    this.data.fat = 0;
    this.data.carbs = 0;
    this.data.water = 0;
    for (let item of this.itemSkus) {
      let mysku = this.addSkuList.filter(e => {
        return e.id == item.sku_id;
      });
      let i = mysku[0];
      let qtyInGrams = skuUnitToGrams(item.unit, item.qty_visual);
      if (i.cost_method == 1) {
        if (i.total_individual_units > 0) {
          this.data.price += (i.price / i.total_individual_units) * qtyInGrams;
        }
      } else {
        if (i.master_weight > 0) {
          this.data.price += (i.price / i.master_weight) * qtyInGrams;
        }
      }
      if (i.nutritional_portion_weight > 0) {
        //		let qtyInGrams= skuUnitToGrams(item.unit ,item.qty_visual);
        this.data.kcal += (i.kcal / i.nutritional_portion_weight) * qtyInGrams;
        this.data.protein +=
          (i.protein / i.nutritional_portion_weight) * qtyInGrams;
        this.data.fat += (i.fat / i.nutritional_portion_weight) * qtyInGrams;
        this.data.carbs +=
          (i.carbs / i.nutritional_portion_weight) * qtyInGrams;
        this.data.water +=
          (i.water / i.nutritional_portion_weight) * qtyInGrams;
      }
    }
    //	console.log(this.itemBlocks);
    for (let item of this.itemBlocks) {
      let myblock = this.addBlockList.filter(e => {
        return e.id == item.child_recipe_block_id;
      });
      let i = myblock[0];
      if (i.qty > 0) {
        this.data.price += (i.price / i.qty) * item.qty;
        this.data.kcal += (i.kcal / i.qty) * item.qty;
        this.data.protein += (i.protein / i.qty) * item.qty;
        this.data.fat += (i.fat / i.qty) * item.qty;
        this.data.carbs += (i.carbs / i.qty) * item.qty;
        this.data.water += (i.water / i.qty) * item.qty;
      }
    }

    /*	console.log("updateBlock");
    console.log(this.data);
*/
  }

  addSku() {
    this.itemSkus.push(this.newSku);
    this.updateBlock();
    //		console.log(this.itemSkus);
    this.initNewSku();
  }

  addBlock() {
    this.itemBlocks.push(this.newBlock);
    this.initNewBlock();
    this.updateBlock();
  }

  deleteObj(list, obj) {
    /*		console.log("Deleting");
    console.log(list);
    console.log(obj);*/
    if (obj.id) {
      this.recipeBlockItemService.delete(obj.id).subscribe(data => {
        let idx = list.indexOf(obj);
        list.splice(idx, 1);
      });
    } else {
      let idx = list.indexOf(obj);
      list.splice(idx, 1);
    }
    this.updateBlock();
  }

  cancel() {
    this.router.navigate(['/inventory/recipe']);
  }
}
