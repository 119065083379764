<div class="row text-center freemium-header">
  <div class="col-12 px-5 py-3">
    <h3 class="mt-0 mb-3">Upgrade to Pro Now!</h3>
    <h5 class="mb-5">
      Get more than scheduling from the Cheddrsuite you know and love. Upgrade
      to receive access to a whole suite of tools designed to simplify the
      management of your food and beverage business.
    </h5>
    <button
      class="btn btn-primary btn-lg mb-3"
      [routerLink]="'/settings/account/'+getLocationId()+'/package'">
      {{upgradeText}}
    </button>
  </div>
</div>

<div class="container-fluid p-5">
  <div class="row mt-2">
    <div class="col-12">
      <h3>Features</h3>
      <h4>
        Unlock ALL of these back-of-house management tools for one low monthly
        fee!
      </h4>
    </div>
  </div>

  <div id="freemium-log" class="freemium row mt-2">
    <div class="freemium-image col-12 col-md-6 mb-4">
      <img src="/images/freemium/logs.jpg" alt="Log" />
    </div>
    <div class="col-12 col-md-6 col-lg-4 offset-lg-1">
      <h4 class="display">Log</h4>
      <p class="lead">
        Advanced control and reporting to help you stay on top of operations.
      </p>
      <ul>
        <li>Daily Checklists for employees</li>
        <li>Sales by service time</li>
        <li>Shift logs</li>
        <li>Invoices</li>
      </ul>
      <button
        class="btn btn-primary"
        [routerLink]="'/settings/account/'+getLocationId()+'/package'">
        {{upgradeText}}
      </button>
    </div>
  </div>

  <div id="freemium-inventory" class="freemium row mt-3">
    <div class="freemium-image col-12 col-md-6 order-md-1 offset-lg-1 mb-4">
      <img src="/images/freemium/inventory.jpg" alt="Inventory" />
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <h4 class="display">Inventory</h4>
      <p class="lead">Maintain stock minimums with automated PO generation</p>
      <ul>
        <li>Take inventory</li>
        <li>Create recipes</li>
        <li>Track ingredients</li>
        <li>Purchase orders and standing orders</li>
      </ul>
      <button
        class="btn btn-primary"
        [routerLink]="'/settings/account/'+getLocationId()+'/package'">
        {{upgradeText}}
      </button>
    </div>
  </div>
  <div id="freemium-doc" class="freemium row mt-3">
    <div class="freemium-image col-12 col-md-6 mb-4">
      <img src="/images/freemium/docs.jpg" alt="Docs" />
    </div>
    <div class="col-12 col-md-6 col-lg-4 offset-lg-1">
      <h4 class="display">Documents</h4>
      <p class="lead">
        Upload and manage all of the documents you need for day-to-day
        operations
      </p>
      <ul>
        <li>Manage employee tax documents</li>
        <li>Upload and update menus</li>
        <li>Create quizzes to verify employee knowledge</li>
        <li>
          Optionally require signature or passing quiz score on docs, and make
          mandatory before sign-on
        </li>
      </ul>
      <button
        class="btn btn-primary"
        [routerLink]="'/settings/account/'+getLocationId()+'/package'">
        {{upgradeText}}
      </button>
    </div>
  </div>
  <div id="freemium-contacts" class="freemium row mt-3">
    <div class="freemium-image col-12 col-md-6 order-md-1 offset-lg-1 mb-4">
      <img src="/images/freemium/contacts.jpg" alt="Vendors" />
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <h4 class="display">Vendors</h4>
      <p class="lead">
        Track and manage vendors by type in one convenient place!
      </p>
      <ul>
        <li>Create groups</li>
        <li>Organize contact information</li>
      </ul>
      <button
        class="btn btn-primary"
        [routerLink]="'/settings/account/'+getLocationId()+'/package'">
        {{upgradeText}}
      </button>
    </div>
  </div>
  <div id="freemium-reports" class="freemium row mt-3">
    <div class="freemium-image col-12 col-md-6 mb-4">
      <img src="/images/freemium/reports.jpg" alt="Reports" />
    </div>
    <div class="col-12 col-md-6 col-lg-4 offset-lg-1">
      <h4 class="display">Reports</h4>
      <p class="lead">
        Comprehensive overviews of revenue, cost and goals in real time
      </p>
      <ul>
        <li>Sales reports</li>
        <li>Payroll reports</li>
        <li>Easy profit/loss statements</li>
        <li>Set and manage business goals</li>
      </ul>
      <button
        class="btn btn-primary"
        [routerLink]="'/settings/account/'+getLocationId()+'/package'">
        {{upgradeText}}
      </button>
    </div>
  </div>
  <div id="freemium-texting" class="freemium row mt-3">
    <div class="freemium-image col-12 col-md-6 order-md-1 offset-lg-1 mb-4">
      <img src="/images/freemium/texting.jpg" alt="Texting" />
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <h4 class="display">Texting</h4>
      <p class="lead">
        Update your employees via text when publishing a new schedule!
      </p>
      <button
        class="btn btn-primary"
        [routerLink]="'/settings/account/'+getLocationId()+'/package'">
        {{upgradeText}}
      </button>
    </div>
  </div>
</div>
