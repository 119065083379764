import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  AccountService,
  IUser,
  IApiLocation,
} from '../services/account.service';
import {
  PurchaseOrderService,
  IPurchaseOrder,
  PurchaseOrderStatus,
} from '../services/purchaseOrder.service';
import { LogService } from '../services/log.service';
import { SalesGoalService } from '../services/salesGoal.service';
import { baseRole } from '../shared/baseRole';
import { DateMenuType, getEndOfWeek } from '../shared/api';

@Component({
  templateUrl: './purchaseOrder.html',
})
export class PurchaseOrderComponent
  extends baseRole
  implements OnInit, OnDestroy {
  public DateMenuType = DateMenuType;
  public PurchaseOrderStatus = PurchaseOrderStatus;
  public data: IPurchaseOrder[];
  public startDate: Date;
  public endDate: Date;
  public pendingPrice = 0;
  public pendingPercent = 0;
  public approvedPrice = 0;
  public approvedPercent = 0;
  public estSales = 0;
  public budget = 0;
  public sub;
  public poSub;

  public approvalOrder: IPurchaseOrder;

  // Resend stuff
  @ViewChild('resendModal')
  public resendModal: any;

  @ViewChild('approveModal')
  public approveModal: any;

  public sendEmail: boolean = true;
  public hasEmail: boolean;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected logService: LogService,
    protected poService: PurchaseOrderService,
    protected salesGoalService: SalesGoalService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.ManageInventory)) {
      this.router.navigate(['/']);
      return;
    }
    if (!this.isAllowed(this.Permission.EditPO)) {
      this.router.navigate(['/inventory']);
      return;
    }
    if (this.sub) return;
    this.sub = this.accountService
      .getCurrentDateUpdateObservable(DateMenuType.Weekly)
      .subscribe(date => {
        if (!date) return;
        this.startDate = date;
        this.endDate = new Date(this.startDate);
        this.endDate.setDate(this.endDate.getDate() + 6);
        if (!this.poSub) {
          this.poSub = this.poService.getUpdateObservable().subscribe(() => {
            this.load();
          });
        } else {
          this.load();
        }
      });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.poSub) this.poSub.unsubscribe();
  }

  load() {
    this.poService
      .list({ start: this.startDate, end: this.endDate })
      .subscribe(data => {
        this.data = data.filter(e => {
          return (
            e.status == PurchaseOrderStatus.Pending ||
            e.status == PurchaseOrderStatus.Received ||
            e.status == PurchaseOrderStatus.Approved
          );
        });
      });

    this.approvedPrice = 0;
    this.approvedPercent = 0;
    this.pendingPrice = 0;
    this.pendingPercent = 0;

    let loc = this.accountService.getLocation();
    this.salesGoalService
      .list({ start: this.startDate, end: this.endDate })
      .subscribe(goals => {
        this.estSales = 0;
        for (let g of goals) {
          this.estSales += g.goal;
        }
        this.budget = this.estSales * (loc.cogs_goal / 100);

        this.poService
          .sumByType(this.startDate, this.endDate)
          .subscribe(prices => {
            for (let p of prices) {
              if (p.status == PurchaseOrderStatus.Approved) {
                this.approvedPrice += p.price;
                if (this.budget) {
                  this.approvedPercent = this.approvedPrice / this.budget;
                }
              }
              if (p.status == PurchaseOrderStatus.Pending) {
                this.pendingPrice += p.price;
                if (this.budget) {
                  this.pendingPercent = this.pendingPrice / this.budget;
                }
              }
            }
          });
      });
  }

  showApproval(obj) {
    this.approvalOrder = obj;
    this.hasEmail = obj.contact_email && obj.contact_email.length > 0;
    this.sendEmail = this.hasEmail;
  }

  changeStatus(obj, newStatus: PurchaseOrderStatus) {
    let sendEmail = this.sendEmail;

    // This should be a reset back to approved.
    if (
      obj.status != PurchaseOrderStatus.Pending &&
      newStatus == PurchaseOrderStatus.Approved
    ) {
      sendEmail = false;
    }

    this.poService
      .changeStatus({
        id: obj.id,
        oldStatus: obj.status,
        invoice_id: obj.invoice_id,
        newStatus: newStatus,
        sendEmail: sendEmail,
      })
      .subscribe(data => { });
  }

  resend(obj) {
    this.poService.resend(obj.id).subscribe(data => { });
  }

  deleteObj(obj) {
    this.poService.delete(obj.id).subscribe(() => {
      let idx = this.data.indexOf(obj);
      this.data.splice(idx, 1);
    });
  }

  print(po) {
    let link = this.poService.getPdfUrl({
      location_id: this.getLocationId(),
      purchase_order_id: po.id,
    });
    window.open(link, '_blank');
  }
}
