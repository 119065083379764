<div class="subnav inventory-reports-dropdown">
  <div class="col-md-3 col-12">
      <div class="card-body">
        <select [(ngModel)]="selectedReport" (ngModelChange)="onReportChange()" class="form-control">
          <option value="inventoryQuantity">Inventory Quantity Report</option>
          <option *ngFor="let dashboard of dashboards" [value]="dashboard.id">
            {{dashboard.name}}
          </option>
        </select>
      </div>
  </div>
</div>

<div class="container-fluid inventory-reports-container">
  <div *ngIf="selectedReport === 'inventoryQuantity'">
    <div class="col mb-3 text-center">
      <export-to-csv class="float-right" [data]="inventoryData" [filename]="filename()"></export-to-csv>
      <div class="title text-center">Inventory Quantity Report</div>
      <div class="text-center">
        {{loc.name}} <small>as of {{ now | date: 'short'}}</small>
      </div>
    </div>
    <div class="card">
      <div class="card-body py-2">
        <div class="d-flex">
          <div class="col-2 px-0 pb-4">
            <div class="pl-1">
              <label>Expense Category</label>
            </div>
            <div>
              <select [(ngModel)]="filterData.expense_account_id" (ngModelChange)="filter()" name="expense_account_id"
                class="form-control">
                <option *ngFor="let ic of expenseAccounts" [ngValue]="ic.id">
                  {{ic.name}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label>Number of items: {{ totalNum | number: '1.0-0' }}</label>
          </div>
          <div class="col-6 text-right">
            <label>Total Value: {{ totalPrice | currency }}</label>
          </div>
        </div>

        <!-- Inventory Data Table -->
        <div *ngIf="inventoryData" class="row-table row-striped">
          <div class="row d-md-flex d-none row-header">
            <div class="col-2">Sku</div>
            <div class="col-6">Description</div>
            <div class="col-1 text-md-center">Quantity</div>
            <div class="col-1 text-md-center">Unit</div>
            <div class="col-1 text-md-center">Per unit</div>
            <div class="col-1 text-md-center">Total</div>
          </div>
          <div class="row" *ngFor="let d of inventoryData">
            <div class="col-md-2 col-12">
              <span class="d-md-none label-header">Sku:</span>
              {{d.sku_num}}
            </div>
            <div class="col-md-6 col-12">
              <span class="d-md-none label-header">Description:</span>
              {{d.name}}{{d.id}}
            </div>
            <div class="col-md-1 col-12 text-md-center">
              <span class="d-md-none label-header">Quantity:</span>
              {{d.quantity | number: '1.0-0' }}
            </div>
            <div class="col-md-1 col-12 text-md-center">
              <span class="d-md-none label-header">Unit:</span>
              {{ d.sub_unit}}
            </div>
            <div class="col-md-1 col-12 text-md-center">
              <span class="d-md-none label-header">Per Unit:</span>
              {{d.price/d.qty_inner_units | currency }}
            </div>
            <div class="col-md-1 col-12 text-md-center">
              <span class="d-md-none label-header">Line Total:</span>
              {{d.total | currency }}
            </div>
          </div>
        </div>

        <div class="row border-top">
          <div class="col-md-9 col-12 text-md-right">
            Number of Items: {{ totalNum | number: '1.0-0' }}
          </div>
          <div class="col-md-3 col-12 text-md-right">
            Total Value: {{totalPrice| currency }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedReport !== 'inventoryQuantity'">
    <div class="col mb-3 text-center">
      <div class="title text-center">
        {{ selectedDashboardName }}
      </div>
      <div class="text-center">
        {{loc.name}} <small>as of {{ now | date: 'short'}}</small>
      </div>
    </div>
    <div class="card">
      <div id="qsDashboardContainer" class="dashboard-container"></div>
    </div>
  </div>
</div>