<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Fixed Cost Editor"
  aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content" *ngIf="data && itemSkuList && blockList && skus">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Edit Variation Recipe
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body" *ngIf="data && recipe && items">
        <label>{{recipe.name}} - Variation {{data.name}}</label>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <label>Details</label>
              </div>
              <div class="col-6">
                <label>Nutrition</label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="card-text py-1 grey-stripe-bg">
                  <strong>Price</strong>
                  <span class="float-right">
                    {{ data.price | currency: 'USD'}}
                  </span>
                </div>
              </div>
              <div class="col-6">
                <div class="card-text py-1 grey-stripe-bg">
                  <strong>Kcal</strong>
                  <span class="float-right">
                    {{ data.kcal | number: '1.0-2'}}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="card-text py-1">
                  <strong>Cost</strong>
                  <span class="float-right">
                    {{ data.cost | currency: 'USD'}}
                  </span>
                </div>
              </div>
              <div class="col-6">
                <div class="card-text py-1">
                  <strong>Fat</strong>
                  <span class="card-text py-1 float-right">
                    {{ data.fat | number: '1.0-2'}} g
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="card-text py-1 grey-stripe-bg">
                  <strong>COG %</strong>
                  <span class="float-right" *ngIf="data.cost>0 && data.price>0">
                    <!--{{ 100.0*(data.price - data.cost/data.price) |  number: '1.0-2' }} %  -->
                    {{ data.cost/data.price | percent}}
                  </span>
                </div>
              </div>
              <div class="col-6">
                <div class="card-text py-1 grey-stripe-bg">
                  <strong>Protein</strong>
                  <span class="card-text py-1 float-right">
                    {{ data.protein | number: '1.0-2'}} g
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="card-text py-1">
                  <strong>Items</strong>
                  <span class="float-right"> {{ items.length }} </span>
                </div>
              </div>
              <div class="col-6">
                <div class="card-text py-1">
                  <strong>Carbs</strong>
                  <span class="card-text py-1 float-right">
                    {{ data.carbs | number: '1.0-2'}} g
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- any problems copy from recipeBlockEdit-->

        <form ngNativeValidate (submit)="addNewBlock()">
          <label>Recipe in this menu item</label>
          <div class="row">
            <div class="col input-group mb-0">
              <select
                class="form-control-select flex-grow-1"
                [(ngModel)]="newBlock.recipe_block_id"
                name="new_block_id">
                <option *ngFor="let b of addNewBlockList" [value]="b.id">
                  {{b.name}}
                </option>
              </select>
              <div class="input-group-append">
                <input
                  [(ngModel)]="newBlock.qty"
                  type="number"
                  step="any"
                  class="form-control-select"
                  name="new_qty" />
                <div class="input-group-text">Servings</div>
                <button
                  class="btn btn-primary override-invalid"
                  [disabled]="!(newBlock.qty && newBlock.recipe_block_id )">
                  <icons name="Plus"></icons>
                </button>
              </div>
            </div>
          </div>
        </form>
        <div class="card" *ngIf="itemBlockList.length>0">
          <div class="card-body">
            <div class="row" *ngFor="let rb of itemBlockList">
              <div class="col">
                <div class="input-group">
                  <select
                    class="form-control-select flex-grow-1"
                    (ngModelChange)="save(rb)"
                    [(ngModel)]="rb.recipe_block_id"
                    [name]="'recipe_block_id_'+rb.id">
                    <option *ngFor="let b of blockList" [value]="b.id">
                      {{b.name}}
                    </option>
                  </select>
                  <div class="input-group-append">
                    <input
                      [(ngModel)]="rb.qty"
                      (ngModelChange)="save(rb)"
                      type="number"
                      class="form-control-select"
                      [name]="'rb_qty_'+rb.id" />
                    <span class="input-group-text">Servings</span>
                    <button
                      class="btn btn-primary override-invalid"
                      (click)="deleteItem(rb)">
                      <icons name="Trash"></icons>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <label>Ingredients in this menu item</label>
        <div class="row" *ngIf="skus && skus.length>0">
          <form ngNativeValidate style="width: 100%" (submit)="addSkuBlock()">
            <div class="col input-group d-flex">
              <select
                [(ngModel)]="newSku.sku_id"
                (ngModelChange)="changeNewSku()"
                class="form-control-select flex-grow-1"
                name="new_sku">
                <option *ngFor="let s of addNewSkus" [value]="s.id">
                  {{s.name}}
                </option>
              </select>
              <div class="input-group-append">
                <input
                  [(ngModel)]="newSku.qty_visual"
                  type="number"
                  step="any"
                  class="form-control-select"
                  name="new_sku_qty_visual" />
                <select
                  *ngIf="newSku.sku.cost_method != 1"
                  [(ngModel)]="newSku.unit"
                  class="form-control-select"
                  name="new_sku_unit">
                  <option *ngFor="let k of SkuUnitKeys" [value]="k">
                    {{SkuUnitNames[k]}}
                  </option>
                </select>
                <span
                  *ngIf="newSku.sku.cost_method == 1"
                  class="input-group-text"
                  >Units</span
                >
                <button
                  class="btn btn-primary override-invalid"
                  [disabled]="!(newSku.qty && newSku.sku_id && newSku.unit)">
                  <icons name="Plus"></icons>
                </button>
              </div>
            </div>
          </form>
        </div>

        <div class="card mt-1" *ngIf="itemSkuList.length>0">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="input-group" *ngFor="let i of itemSkuList">
                  <select
                    [(ngModel)]="i.sku_id"
                    (ngModelChange)="findCostMethod(i)"
                    class="form-control-select flex-grow-1"
                    [name]="'sku_'+i.id">
                    <option *ngFor="let s of skus" [value]="s.id">
                      {{s.name}}
                    </option>
                  </select>
                  <div class="input-group-append">
                    <input
                      [(ngModel)]="i.qty_visual"
                      (ngModelChange)="save(i)"
                      type="number"
                      class="form-control-select"
                      [name]="'sku_qty_'+i.id"
                      required
                      min="1" />
                    <span
                      *ngIf="i.cost_method==1"
                      class="input-group-text"
                      style="width: 90px"
                      >Units</span
                    >
                    <select
                      *ngIf="i.cost_method!=1"
                      [(ngModel)]="i.unit"
                      (ngModelChange)="save(i)"
                      class="form-control-select"
                      [name]="'sku_unit_'+i.id">
                      <option *ngFor="let k of SkuUnitKeys" [value]="k">
                        {{SkuUnitNames[k]}}
                      </option>
                    </select>
                    <button
                      class="btn btn-primary override-invalid"
                      (click)="deleteItem(i)">
                      <icons name="Trash"></icons>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="cancel()"></cancel-button>
            <done-button
              class="float-right mr-2"
              (click)="cancel()"></done-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
