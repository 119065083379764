<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Add Shift
          <button type="button" class="close" aria-label="Close" (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <global-error *ngFor="let error of errors" [error]="error"></global-error>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="card my-1">
              <div class="card-body" *ngIf="positions">
                <label>Choose a Position</label>

                <select [(ngModel)]="positionId" (ngModelChange)="filterUsersByPosition()" name="position_id"
                  class="form-control d-inline-block">
                  <option *ngFor="let p of positions | orderBy: 'name'" [value]="p.id">
                    {{p.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="card my-1">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-6 col-12">
                    <label>Start Time</label>
                    <timepicker [(ngModel)]="startTime" name="start" minuteStep="15" (ngModelChange)="calcConflicts()">
                    </timepicker>
                  </div>
                  <div class="col-sm-6 col-12">
                    <label>End Time</label>
                    <timepicker [(ngModel)]="endTime" name="end" minuteStep="15" (ngModelChange)="calcConflicts()">
                    </timepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="firstBreak" class="col-lg-4 col-12">
            <div class="card my-1">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <label>Break</label>

                  <div class="custom-control custom-switch custom-switch-lg">
                    <input [(ngModel)]="firstBreak.toggle" type="checkbox" class="custom-control-input"
                      id="firstBreakSwitch" />
                    <label class="custom-control-label" for="firstBreakSwitch">
                    </label>
                  </div>
                </div>

                <div *ngIf="firstBreak.toggle" class="row">
                  <div class="col-xl-8 col-lg-12 col-sm-8 col-12">
                    <timepicker [(ngModel)]="firstBreak.time" name="firstBreak" minuteStep="15"></timepicker>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-sm-4 col-12">
                    <select class="form-control" style="max-width: 7rem" [(ngModel)]="firstBreak.length">
                      <option *ngFor="let number of numbers" [ngValue]="number">
                        <span *ngIf="number == 0">None</span>
                        <span *ngIf="number != 0"> {{number}}min</span>
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="secondBreak" class="col-lg-4 col-12">
            <div class="card my-1">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <label>Break</label>
                  <div class="custom-control custom-switch custom-switch-lg">
                    <input [(ngModel)]="secondBreak.toggle" type="checkbox" class="custom-control-input"
                      id="secondBreakSwitch" />
                    <label class="custom-control-label" for="secondBreakSwitch">
                    </label>
                  </div>
                </div>
                <div *ngIf="secondBreak.toggle" class="row">
                  <div class="col-xl-8 col-lg-12 col-sm-8 col-12">
                    <timepicker [(ngModel)]="secondBreak.time" name="secondBreak" minuteStep="15"></timepicker>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-sm-4 col-12">
                    <select class="form-control" style="max-width: 7rem" [(ngModel)]="secondBreak.length">
                      <option *ngFor="let number of numbers" [ngValue]="number">
                        <span *ngIf="number == 0">None</span>
                        <span *ngIf="number != 0"> {{number}}min</span>
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="thirdBreak" class="col-lg-4 col-12">
            <div class="card my-1">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <label>Break</label>
                  <div class="custom-control custom-switch custom-switch-lg">
                    <input [(ngModel)]="thirdBreak.toggle" type="checkbox" class="custom-control-input"
                      id="thirdBreakSwitch" />
                    <label class="custom-control-label" for="thirdBreakSwitch">
                    </label>
                  </div>
                </div>
                <div *ngIf="thirdBreak.toggle" class="row">
                  <div class="col-xl-8 col-lg-12 col-sm-8 col-12">
                    <timepicker [(ngModel)]="thirdBreak.time" name="thirdBreak" minuteStep="15"></timepicker>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-sm-4 col-12">
                    <select class="form-control" style="max-width: 7rem" [(ngModel)]="thirdBreak.length">
                      <option *ngFor="let number of numbers" [ngValue]="number">
                        <span *ngIf="number == 0">None</span>
                        <span *ngIf="number != 0"> {{number}}min</span>
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-1" *ngIf="days && users">
          <div class="card-body">
            <label>Select Days</label>
            <div class="flex-7 px-1" *ngFor="let d of days">
              <input-toggle [(ngModel)]="d._selected" (ngModelChange)="changeSelected()">{{ d.date | date: 'EEE, MMM
                d'}}</input-toggle>
            </div>
          </div>
        </div>

        <div class="card" *ngIf="users">
          <div class="card-body pb-2">
            <label>Number of Open Shifts to create
              <small>(no assigned employees)</small></label>
            <input [(ngModel)]="unassigned" type="number" class="form-control" style="width: 24%"
              name="unassigned_slots" min="0" max="20" placeholder="0" />
            <label>Assign Staff - <small>weekly labor stats</small></label>
            <div class="row font-weight-bold p-1 mb-2 mx-0" style="background-color: #c6c8ca">
              <div class="col-2 text-center d-none d-md-block">Name</div>
              <div class="col-1 d-none d-md-block">Hours</div>
              <div class="col-1 d-none d-md-block">Shifts</div>
              <div class="col-4 text-center d-none d-md-block">
                Preferences <small>Click</small>
              </div>
              <div class="col-3 text-center d-none d-md-block">Scheduled</div>
              <!-- going to make Preference a link to their preferences -->
            </div>
            <div class="row mx-0 my-1 border-bottom" *ngFor="let u of users | orderBy:'lastname'">
              <div class="col-md-2">
                <input-toggle [(ngModel)]="u._selected" (ngModelChange)="changeSelected()" [name]="'user_'+u.id"
                  class="mb-1"><span style="line-height: 1rem"><b>{{u.firstname}} {{u.lastname}} </b>
                    <div>
                      <small>
                        <phone [phone]="u.cell"></phone>
                      </small>
                    </div>
                  </span></input-toggle>
              </div>
              <div class="col-md-1">
                <span class="d-md-none text-primary">Hours: </span>{{u.minutes|
                bliMinutes}}
                <small class="text-dark">Max {{ u.max_hours }}/week {{u.ot_hours}}/day</small>
              </div>
              <div class="col-md-1">
                <span class="d-md-none text-primary">Shifts: </span>{{
                u.shift_count }}
              </div>
              <div class="col-md-4 pointer d-md-none mb-1" (click)="u._viewPref=!u._viewPref">
                <span class="text-primary">Preferences: click </span>
                <work-pref-graph [prefs]="u.pref_dna" *ngFor="let dayIndex of selectedDays" [day]="dayIndex"
                  [style.height]="20.0/(selectedDays.length?selectedDays.length:1)+'%'"></work-pref-graph>
              </div>
              <div class="col-md-4 pointer d-none d-md-block mb-1" (click)="u._viewPref=!u._viewPref">
                <work-pref-graph [prefs]="u.pref_dna" *ngFor="let dayIndex of selectedDays" [day]="dayIndex"
                  [style.height]="100.0/(selectedDays.length?selectedDays.length:1)+'%'"></work-pref-graph>
              </div>
              <div class="col-md-3">
                <span class="d-md-none text-primary">Warnings: </span>
                <span *ngIf="u.overMax || u.ot">
                  <span *ngIf="u.ot" class="text-danger mr-4">Overtime </span>
                  <span *ngIf="u.overMax" class="text-dark">Over Max hours
                  </span>
                  <br />
                </span>
                <div *ngFor="let c of u.conflicts" class="text-danger">
                  {{ c.name }}
                  <span *ngIf="c.start">
                    {{ c.start | date: 'MMM d h:mm a'}} - {{ c.end | date: 'MMM
                    d h:mm a'}} <br />
                  </span>
                  <span *ngIf="c.minutes">
                    with {{c.minutes |bliMinutes}} scheduled in pay week.
                  </span>
                </div>

                <span *ngFor="let c of u.notifications" class="text-dark">
                  {{ c.name}}
                  <span *ngIf="c.start">
                    {{ c.start | date: 'MMM d h:mm a'}} - {{ c.end | date: 'MMM
                    d h:mm a'}} <br />
                  </span>
                </span>
              </div>
              <div class="col-12 pointer" *ngIf="u._viewPref" (click)="u._viewPref=false">
                <shift-preferences [(ngModel)]="u.pref_dna" [disabled]="true"></shift-preferences>
              </div>
            </div>
          </div>

          <div class="row mt-3 pb-2 px-2">
            <div class="col-sm-12">
              <cancel-button class="float-right" (click)="cancel()"></cancel-button>
              <save-button class="float-right" (click)="save()"></save-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>