export const environment = {
  production: false,
  maintenance: false,
  supportEmail: 'support@cheddrsuite.com',
  devEmail: 'robert.kugler@cheddrsuite.com',
  demoEmail: 'demo@cheddrsuite.com',
  freeTrial: {
    enabled: false,
    days: 14,
  },
  stripePublishableKey:
    'pk_test_51N0w30J3XKVio0bBjqBKBzOAoAR8alujCMk63ccYtiOqh20csfuHup0WHqHopRVqiilF3cfO06eoXFOvpK7kgWy500iGRqkMhu',
  stripeBaseUrl: 'https://dashboard.stripe.com/test/',
  kanmonUrl: 'https://cdn.sandbox.kanmon.dev/scripts/kanmon-connect.js',
  partnerStackApiKey: 'pk_3jvDiEFpuIAnpZ8exvXRN52y1qORbGDl',
  posthogApiKey: 'phc_qXVy03WPzOmJpp1wYJXq30BGlsfnQ4xwiVII91e1ZYj',
  posthogHost: 'https://app.posthog.com',
  vendorsIntegrationBaseUrl: 'https://vendors.api.cheddrsuite.com/',
  vendorsIntegrationApiKey: 'jN5UmWfEdH16WNK3AuTJX3yAfGWue04847vIbhZu',
};
